type AsType = 'l' | 's' | 'xs' | 'nav' | string

interface ButtonAndLinksProps {
	as?: AsType // 'as' is optional now
	body: string | undefined
	className?: string
}

const ButtonAndLinks = ({ as, body, className = '' }: ButtonAndLinksProps) => {
	const baseClasses = 'font-normal'
	const sizeClasses: { [key in AsType]?: string } = {
		l: 'text-[22px] leading-7 ',
		s: 'text-[18px] leading-[1.375rem]',
		xs: 'text-[14px] leading-6',
		nav: 'text-[16px] leading-6',
		'button-s': 'text-[16px] leading-6',
	}

	// If 'as' is provided, use the corresponding size class; otherwise, use an empty string
	const sizeClass = as ? sizeClasses[as] : ''

	// Combine the classes while ensuring that 'as' is optional
	const combinedClasses = `${baseClasses} ${sizeClass} ${className}`.trim()

	// Return the element with the combined classes
	return <p className={combinedClasses}>{body}</p>
}

export default ButtonAndLinks
